// import React from "react";
// import Trans from "./Trans.js";
// import { Document, Page } from 'react-pdf/renderer';
// import { useState, forwardRef, useImperativeHandle, useRef } from "react";

// function PurchaseTermsViewer() {

//     const pdfURL = 'https://quote.equimetre.com/202409_ARIONEO_CGV_CGL_ENG.pdf';

//     var quoteTermsClassname = "rounded-border quote-terms";

//     switch (props.dealtype) {
//         case "EQUIMETRE FLAT":
//             quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre text-center";
//             break;
//         case "EQUIMETRE TROT":
//             quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre text-center";
//             break;
//         case "EQUIMETRE VET":
//             quoteTermsClassname =
//                 "rounded-border quote-terms quote-terms-equimetre-vet text-center";
//             break;
//         case "EQUISYM":
//             quoteTermsClassname = "rounded-border quote-terms quote-terms-equisym text-center";
//             break;
//         case "Equiscience":
//             quoteTermsClassname = "rounded-border quote-terms quote-terms-equiscience text-center";
//             break;
//         default:
//             break;
//     }

//     return (
//         <div className="fixed-width">
//             <Document file={pdfURL}>
//                 <Page pageNumber={1} />
//             </Document>
//         </div>
//     );
// }

// export default PurchaseTermsViewer;
