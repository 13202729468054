import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useState } from "react";
import Button from '@mui/material/Button';

//COMPONENTS
import Trans from "./Trans.js";

const BillingPaymentMethodsForm = ({ customer }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // console.log(submitError)
            // handleError(submitError);
            return;
        }

        // Create the SetupIntent and obtain clientSecret
        const responseClientSecret = await fetch("/getCreateSetupIntent?customerId=" + customer.id);
        const { sclient: clientSecret } = await responseClientSecret.json();

        // Use the clientSecret and Elements instance to confirm the setup
        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
                return_url: window.location.href,
            },
        });

        if (error) {
            // console.log(error)
            // handleError(error);
        }
    };

    const paymentElementOptions = {
        layout: "tabs"
    }
    return (
        <form id="payment-form" >
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <Button sx={{ mt: 2 }} variant="outlined" disabled={false} onClick={handleSubmit}>
                <Trans text="save" />
            </Button>
            {/* Show any error that happens when processing the payment */}
            {
                error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )
            }
        </form >
    )
};

export default BillingPaymentMethodsForm;