const lang = {
    "test": "This is a test",
    "reference": "Reference",
    "for": "For",
    "comments": "Comments",
    "quote_default_title_equimetre": "Equimetre Purchase Order",
    "quote_default_title_equisym": "Equisym Purchase Order",
    "quote_default_title_equiscience": "Equiscience Purchase Order",
    "line_items_title": "Products & Services",
    "price_summary_discount": "discount",
    "per_year": "/ year",
    "per_month": "/ month",
    "here": "here",
    "user_forms_title": "Order completion form",
    "user_forms_subtitle": "Please fill in the following information to validate your order with Arioneo. If you have any questions, do not hesitate to call your sales representative.",
    "user_forms_your_email": "Your E-mail",

    "user_forms_equimetre_title": "Details of the Equimetre account owner: who will be in charge of the data?",
    "user_forms_equimetre_subtitle": "These are the details of the person who will log in to the Equimetre platform. We use it to create your account and to associate your Equimetre to this same account. An email will be sent to the account address to activate it by choosing a password.",
    "user_forms_equimetre_email": "Email attached to the Equimetre account",
    "user_forms_equimetre_firstname": "First name attached to the Equimetre account",
    "user_forms_equimetre_lastname": "Last name attached to the Equimetre account",
    "user_forms_equimetre_role": "Who will be the person in charge of the account?",
    "user_forms_equimetre_role_placeholder": "Please select the role that most closely matches the person's",

    "user_forms_equisym_title": "Details of the Equisym account owner.",
    "user_forms_equisym_subtitle": "These are the details of the person who will log in to the Equisym platform. We use it to create your account and to associate your Equisym to this same account.",
    "user_forms_equisym_email": "Email attached to the Equisym account",
    "user_forms_equisym_firstname": "First name attached to the Equisym account",
    "user_forms_equisym_lastname": "Last name attached to the Equisym account",
    "user_forms_equisym_role": "Who will be the person in charge of the account?",
    "user_forms_equisym_role_placeholder": "Please select the role that most closely matches the person's",

    "user_forms_billing_title": "Billing information: who will be in charge of the bills?",
    "user_forms_billing_subtitle": "This information will be forwarded to our accounting department and will be used for future billing purposes.",
    "user_forms_billing_firstname": "First name for billing",
    "user_forms_billing_lastname": "Last name for billing",
    "user_forms_billing_company": "Billed Company",
    "user_forms_billing_email": "E-mail for billing matters",
    "user_forms_billing_phone": "Phone number for billing matters (compulsory)",
    "user_forms_billing_addressline1": "Billing address line 1",
    "user_forms_billing_addressline2": "Billing address line 2",
    "user_forms_billing_addressline3": "Billing address line 3",
    "user_forms_billing_zipcode": "Billing zip code",
    "user_forms_billing_city": "Billing city",
    "user_forms_billing_country": "Billing country",
    "user_forms_billing_country_placeholder": "Please Select",
    "user_forms_billing_state_code": "Billing state code (US only)",
    "user_forms_billing_vatnumber_title": "Intra community VAT number",
    "user_forms_billing_vatnumber_subtitle": "Only for EU countries, except France, you must enter your VAT number so that it is not invoiced",

    "user_forms_billing_to_delivery_checkbox_title": "Shipping info identical to billing info",
    "user_forms_billing_to_equimetre_checkbox_title": "Equimetre account info identical to billing info",

    "user_forms_delivery_title": "Shipping details",
    "user_forms_delivery_subtitle": "This information is for the person who will be in charge of receiving the packages. We will communicate to him/her all updates related to the shipment of the order. ",
    "user_forms_delivery_firstname": "First name for delivery",
    "user_forms_delivery_lastname": "Last name for delivery",
    "user_forms_delivery_company": "Company for delivery",
    "user_forms_delivery_email": "Email for delivery updates",
    "user_forms_delivery_phone": "Phone number for delivery updates",
    "user_forms_delivery_addressline1": "Shipping address line 1",
    "user_forms_delivery_addressline2": "Shipping address line 2",
    "user_forms_delivery_addressline3": "Shipping address line 3",
    "user_forms_delivery_zipcode": "Zip code shipping address",
    "user_forms_delivery_city": "City shipping address",
    "user_forms_delivery_country": "Country shipping address",
    "user_forms_delivery_country_placeholder": "Please Select",
    "user_forms_delivery_state_code": "Shipping state code (US only)",

    "user_forms_requested_field": "This field is mandatory",
    "user_forms_complete_all_required_fields": "Please complete all required fields.",
    "user_forms_wrong_tva_number": "Invalid VAT number (it must be composed of 2 letters then numbers).",

    "payment_method_title": "Payment Method",
    "payment_method_subtitle": "Select your payment method",
    "payment_method_credit_card": "Credit card",
    "payment_method_sepa": "SEPA mandate (Europe only and not Equiscience)",
    "payment_method_bank_transfer": "Bank transfer",
    "payment_method_check": "Check",

    "purchase_terms_title": "Purchase Terms (this paragraph is reffering to the General Terms and Conditions)",
    "purchase_terms_terms_of_payment": "Terms of payment",

    "purchase_terms_line1": "First payment on signature of quotation, balance of the first invoice upon shipping.",
    "purchase_terms_line2": "Next payments on the annual or monthly anniversary date of the subscription. In the case of a subscription with a commitment, the customer undertakes to pay all the monthly or annual fees until the end of the contract. If the Customer terminates his contract before the end of the minimum commitment period defined in the order form, he will be liable for the subscription charges remaining until the end of this period.",

    "purchase_terms_line1_eqs": "If purchase of the product: first payment on signature of quotation, balance upon shipping.",
    "purchase_terms_line2_eqs": "If rental offer: first payment on signature of quotation, balance upon shipping. Next payments on the annual or monthly anniversary date of the subscription. The Customer commits to a duration of forty-eight (48) months.",

    "purchase_terms_line1_eqse": "Training prices are quoted in euros, exclusive of VAT at the current rate. A deposit will be requested at the time of registration, and payment will be finalized upon receipt of the invoice.",
    "purchase_terms_line2_eqse": "Invoices can be paid by bank transfer or credit card.",

    "purchase_terms_line3": "(ARIONEO) LIM FRANCE, a simplified stock company, with a capital of 515 665,78 euros, registered at the Périgueux (France) Trade and Companies Register, under the number 420 738 577, which has its headquarters located at NONTRON (24300), Bellevue, 164 rue Marie Louise Acebe.",

    "purchase_terms_terms_and_conditions": "General Terms and Conditions",

    "purchase_terms_acceptance_title": "You must accept, read and understand the General Terms and Conditions",
    "purchase_terms_acceptance_subtitle": "By checking this box, I accept the",
    "purchase_terms_acceptance_checked": "By validating your order form, you have accepted the",

    "quote_total_recurring_discount": "Recurring discount",
    "quote_total_recurring_subtotal": "Recurring subtotal",
    "quote_total_onetime_discount": "One-time discount",
    "quote_total_onetime_subtotal": "One-time subtotal",
    "quote_total_vat": "20% VAT",
    "quote_total_total_novat": "Total (excl. VAT)",
    "quote_total_total": "Total (incl. VAT)",
    "quote_total_expiration": "This quote expires on",
    "quote_deposit": "Deposit to confirm your order (Credit Card or SEPA only)",

    "actions_checkout": "Validate this purchase order and Pay",
    "actions_checkout_subtitle": "This operation may take several seconds ...",
    "actions_in_progress": "In progress ... Please wait.",

    "actions_save": "Save for later",
    "actions_save_results": "Save details with success",

    "footer_title": "Questions? Contact me",
    "footer_download_quote": "Download quote",
    "footer_print_quote": "Print quote",

    "bank_transfert_title": "Bank transfer",
    "bank_transfert_bank_details": "Details",
    "bank_transfert_thanks": "Thank you for your order, it will be shipped once the transfer is received.",
    "bank_transfert_bank_address": "Bank address",
    "bank_transfert_order": "Please indicate the following transfer reference",
    "bank_transfert_account_name": "Account name",
    "bank_transfert_wrong_method": "If you wish to change your payment method, please contact your sales representative.",

    "following_subtitle": "Thanks, your order is validated.",
    "following_order_title": "Delivery status",
    "following_order_subtitle_preparing": "Your order is being prepared...",
    "following_order_subtitle_sent": "Your order has been shipped. You can track the parcel here:",
    "following_order_button_tracking": "Tracking link",

    "following_invoice_title": "Invoices",
    "following_invoice_subtitle_unavailable": "Your invoice is not yet available",
    "following_invoice_subtitle_available": "Your invoice is available here:",
    "following_invoice_subtitle_email": "Your invoices will be sent by email to the address:",

    "quote_expired": "Your quote has expired.",

    "iban_fr": "FR76 3000 4025 6100 0114 2499 322",
    "iban_us": "FR76 3000 4007 1400 0100 0203 486",
    "iban_uk": "FR76 3000 4024 9700 0100 3738 387",

    "bic_fr": "BNPAFRPPXXX",
    "bic_us": "BNPAFRPPXXX",
    "bic_uk": "BNPAFRPPCTO",

    "invoice_listing": "List of invoices",
    "invoice_date": "Date",
    "invoice_amount": "Amount",
    "invoice_status": "Status",
    "invoice_file": "File",

    "status_draft": "Draft",
    "status_open": "Due",
    "status_void": "Canceled",
    "status_paid": "Paid",
    "status_uncollectible": "Uncollectible",

    "billing": "Billing",
    "billing_title": "ARIONEO - Billing",
    "billing_informations": "Billing informations",
    "billing_id": "Billing id",
    "billing_email": "Billing email",
    "billing_customer_since": "Customer since",
    "billing_invoices": "Invoices",
    "billing_next_invoice_on": "Next invoice on",
    "billing_credit_notes_applied": "Credit notes applied",
    "blling_situation": "Billing situation",
    "billing_warning": "Please note that your account currently has unpaid invoices totaling",
    "billing_success": "Great news! Your account is up to date with no outstanding invoices. Thank you for your prompt payments and continued support!",
    "billing_payment_methods": "Payment methods",
    "billing_set_default": "Set as default",
    "billing_subscriptions": "Subscriptions",
    "billing_payment_page": "Payment page",
    "billing_link": "Go to billing dashboard",
    "billing_quotes": "Quotes",
    "billing_quotes_link": "Link",
    "billing_quotes_name": "Name",
    "billing_quotes_view_quote": "View the quote",
    "billing_invoices_amount": "Amount",
    "billing_invoices_date": "Date",
    "billing_invoices_status": "Status",
    "billing_invoices_sales_order": "Sales order",
    "billing_invoices_invoice": "Invoice",

    "month": "monthly",
    "year": "annually",
    "for": "for",
    "paid": "Paid",
    "past_due": "Past due",
    "open": "Open",
    "warning": "Warning",
    "success": "Success",
    "save": "Save",
    "card": "Card",
    "expires": "Expires",
    "mastercard": "Mastercard",
    "visa": "Visa",
    "american_express": "American Express",
    "sepa": "SEPA Direct Debit",
    "link": "Link",
    "default": "Default",
    "delete": "Delete",
    "active": "Active",
    "start_on": "Start on",
    "paused": "Paused"

};

export default lang;