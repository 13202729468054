import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from "react";

//COMPONENTS
import Trans from "./Trans.js";
import SvgIconMasterCard from './Icons/SvgIconMasterCard.js';
import SvgIconVisa from './Icons/SvgIconVisa.js';
import SvgIconAmericanExpress from './Icons/SvgIconAmericanExpress.js';
import SvgIconCB from './Icons/SvgIconCB.js';
import SvgIconStripe from './Icons/SvgIconStripe.js';

function BillingPaymentMethodsItemsRow({ customer, paymentMethod, onPaymentMethodsChange, loadingPaymentMethods }) {

    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetAsDefault = (event) => {
        handleClose();
        setLoading(true);
        onPaymentMethodsChange()
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                data: {
                    customerId: customer.id,
                    paymentMethodId: event.target.value,
                },
            }),
        };

        fetch("/updatePaymentMethodForCustomerId", requestOptions)
            .then((response) => response.json())
            .then((json) => {
                onPaymentMethodsChange()
            })
    }

    const handleDelete = (event) => {
        handleClose();
        setLoading(true);
        onPaymentMethodsChange()
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                data: {
                    customerId: customer.id,
                    paymentMethodId: event.target.value,
                },
            }),
        };

        fetch("/deletePaymentMethodForCustomerId", requestOptions)
            .then((response) => response.json())
            .then((json) => {
                onPaymentMethodsChange()
            })
    }

    useEffect(() => {
        setLoading(loadingPaymentMethods);
    }, [loadingPaymentMethods]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    var avatarIcon = ""
    var name = ""
    var digit = ""

    switch (paymentMethod.type) {
        case "card":
            switch (paymentMethod.card.brand) {
                case "mastercard":
                    avatarIcon = <SvgIconMasterCard fontSize="large" />
                    name = "mastercard"
                    digit = "••• " + paymentMethod.card.last4
                    break;
                case "visa":
                    avatarIcon = <SvgIconVisa fontSize="large" />
                    name = "visa"
                    digit = "••• " + paymentMethod.card.last4
                    break;
                case "amex":
                    avatarIcon = <SvgIconAmericanExpress fontSize="large" />
                    name = "american_express"
                    digit = "••• " + paymentMethod.card.last4
                    break;
                default:
                    avatarIcon = <SvgIconCB fontSize="large" />
                    name = "card"
                    digit = "••• " + paymentMethod.card.last4
                    break;
            }
            break;
        case "sepa_debit":
            avatarIcon = <SvgIconMasterCard fontSize="large" />
            name = "sepa"
            digit = "••• " + paymentMethod.sepa_debit.last4
            break;
        case "link":
            avatarIcon = <SvgIconStripe fontSize="large" />
            name = "link"
            digit = ""
            break;
        default:
            break;
    }

    return (
        <Stack direction={"row"} spacing={1} alignItems="flex-start" justifyContent="space-between">

            <Stack direction="row" spacing={1}>
                {avatarIcon}
                <Stack spacing={0}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            <Trans text={name} /> {digit}
                        </Typography>
                        {customer && customer.invoice_settings && paymentMethod.id == customer.invoice_settings.default_payment_method && (
                            <Chip label={<Trans text="default" />} color={"info"} size="small" />
                        )}
                        {/*  */}
                    </Stack>
                    {paymentMethod.type == "card" && (
                        <Typography variant="body2" sx={{ color: "#606060" }}>
                            <Trans text="expires" /> {("0" + paymentMethod.card.exp_month).slice(-2)}/{paymentMethod.card.exp_year}
                        </Typography>
                    )}

                </Stack >

            </Stack>

            <IconButton aria-label="delete" disabled={loading}>
                {loading && <CircularProgress size="1rem" color="inherit" />}
                {!loading && <MoreVertIcon onClick={handleClick} />}
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack direction="column" spacing={1}>
                    <Button onClick={handleSetAsDefault} value={paymentMethod.id}><Trans text="billing_set_default" /></Button>
                    <Button onClick={handleDelete} value={paymentMethod.id} startIcon={<DeleteIcon />} color="error"><Trans text="delete" /></Button>
                </Stack>

            </Popover>
        </Stack>

    );
}

export default BillingPaymentMethodsItemsRow;
