//COMPONENTS
import Trans from "./Trans.js";
import { useState, useEffect } from "react";

function InvoicesList({ email }) {

    const [invoices, setInvoices] = useState(null);

    useEffect(() => {
        async function callAPI() {
            const response = await fetch("/getInvoicesFromEmail?email=" + email);
            const json = await response.json();
            setInvoices(json.invoices);
            console.log(json.invoices)
        }

        callAPI();
    }, [email]);

    const downloadInvoice = (filename, filepath) => {
        fetch('/download?filename=' + filename + '&filepath=' + filepath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    var thStyle = {
        "padding-top": "10px",
        "padding-bottom": "10px",
        "padding-left": "50px",
        "padding-right": "50px"
    }

    var tdStyle = {
        "padding-top": "10px",
        "padding-bottom": "10px",
        "padding-left": "50px",
        "padding-right": "50px"
    }

    const tableStyle = {
        "display": "flex",
        "justify-content": "center"
    }

    return (
        <div className="quote-terms-container fixed-width">
            <div className="hs-form__row">
                <div className="hs-form__group">
                    <div className="hs-form__field-row">
                        <div className="hs-form__field-row__column">
                            <div className="rounded-border quote-terms">
                                <h4 className="m-bottom-8 text-center">
                                    <Trans text="invoice_listing"></Trans>
                                </h4>
                                <div style={tableStyle} className="m-bottom-0 is--heading-7 quote-rich-text-body link-obsidian-container">
                                    <table>
                                        <tr>
                                            <th style={thStyle} scope="col"><Trans text="invoice_date"></Trans></th>
                                            <th style={thStyle} scope="col"><Trans text="invoice_amount"></Trans></th>
                                            <th style={thStyle} scope="col"><Trans text="invoice_status"></Trans></th>
                                            <th style={thStyle} scope="col"><Trans text="invoice_file"></Trans></th>
                                        </tr>
                                        {invoices && invoices.map((invoice) =>
                                            <tr key={invoice.filename}>
                                                <td style={tdStyle} scope="row">{new Intl.DateTimeFormat("en-GB", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "2-digit",
                                                }).format(new Date(invoice.date) ?? new Date())}</td>
                                                <td style={tdStyle}>{invoice.total} {invoice.devise}</td>
                                                <td style={tdStyle}><Trans text={"status_" + invoice.status}></Trans></td>
                                                <td style={tdStyle}><button id="downloadBtn" onClick={() => downloadInvoice(invoice.filename, invoice.filepath)} value="download">{invoice.filename}</button></td>
                                            </tr>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoicesList;
