import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconCB() {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path fill="#64B5F6" d="M45,35c0,2.208-1.791,4-4,4H7c-2.209,0-4-1.792-4-4V13c0-2.21,1.791-4,4-4h34c2.209,0,4,1.79,4,4V35z"></path><path fill="#37474F" d="M3 14H45V20H3z"></path><path fill="#E3F2FD" d="M5 22H43V27H5z"></path>
            </svg>
        </SvgIcon>
    );
}