import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

//COMPONENTS
import BillingQuotesItemsTable from "./BillingQuotesItemsTable.js";
import Trans from "./Trans.js";

function BillingQuotes({ deals }) {

    return (
        <div>
            {deals != null && (
                <div className="fixed-width">
                    <Stack spacing={2}>
                        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_quotes" />
                        </Typography>
                        <BillingQuotesItemsTable deals={deals}></BillingQuotesItemsTable>
                    </Stack>
                </div>
            )
            }
        </div>
    );
}

export default BillingQuotes;
