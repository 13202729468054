import "../styles/UserForms.css";

function Checkbox({ name, label, required, value, onChange, errorMessage }) {
  return (
    <div className="hs-form__field">
      <label
        id={name + "-label"}
        className="hs-form__field__label  hs-form__field__checkbox-label"
      >
        <input
          id={name + "-input"}
          aria-labelledby={name + "-label"}
          className="hs-form__field__input "
          type="checkbox"
          name={name}
          aria-invalid="false"
          aria-required="false"
          aria-checked="false"
          checked={value}
          defaultChecked={value}
          onClick={onChange}
        ></input>
        <span className="hs-form__field__checkbox__label-span">{label}</span>
      </label>
      {errorMessage && (
        <div
          id="email__error"
          class="hs-form__field__alert hs-form__field__alert_error"
        >
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

export default Checkbox;
