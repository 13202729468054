import React from "react";
import Trans from "./Trans.js";

function Actions({ dealtype, isLoading }) {
  var buttonClassname = "button-checkout-equimetre";

  switch (dealtype) {
    case "EQUIMETRE FLAT":
      buttonClassname = "button-checkout-equimetre";
      break;
    case "EQUIMETRE TROT":
      buttonClassname = "button-checkout-equimetre";
      break;
    case "EQUIMETRE VET":
      buttonClassname = "button-checkout-equimetre-vet";
      break;
    case "EQUISYM":
      buttonClassname = "button-checkout-equisym";
      break;
    case "Equiscience":
      buttonClassname = "button-checkout-equiscience";
      break;
    default:
      break;
  }

  return (
    <div className="fixed-width">
      <div className="text-center m-top-2 m-bottom-6">
        {isLoading === false && (
          <button
            data-selenium-test="quotes-purchase-btn"
            data-loading="false"
            data-loading-state="idle"
            aria-disabled="false"
            className={
              "uiButton private-button private-button--default private-loading-button custom-color-button private-button--active private-button--non-link " +
              buttonClassname
            }
            data-button-use="tertiary"
            type="submit"
            aria-pressed="true"
          >
            <span className="private-loading-button__content private-button--internal-spacing">
              <div>
                <Trans text="actions_checkout"></Trans>
              </div>
            </span>
          </button>
        )}
        {isLoading === true && (
          <div>
            <button
              data-selenium-test="quotes-purchase-btn"
              data-loading="false"
              data-loading-state="idle"
              aria-disabled="false"
              className="uiButton private-button private-button--default private-loading-button custom-color-button private-button--active private-button--non-link button-checkout-disabled"
              data-button-use="tertiary"
              type="submit"
              aria-pressed="true"
              disabled
            >
              <svg className="spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="4"
                ></circle>
              </svg>
            </button>
            <div>
              <Trans text="actions_checkout_subtitle"></Trans>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Actions;
