import Typography from '@mui/material/Typography';

//COMPONENTS
import BillingSubscriptionsItemsRow from "./BillingSubscriptionsItemsRow.js";

function BillingSubscriptionsItemsTable({ subscriptions, subscriptionSchedules }) {


  let subscriptionsItemsAsArray = Object.keys(subscriptions)
    .map((pid) => subscriptions[pid])
  // .sort((a, b) => Number(b.amount) - Number(a.amount));
  let rows = [];

  subscriptionsItemsAsArray.forEach((lineItem) => {
    rows.push(
      <BillingSubscriptionsItemsRow
        key={lineItem.id}
        lineItem={lineItem}
      ></BillingSubscriptionsItemsRow>
    );
  });

  let subscriptionSchedulesItemsAsArray = Object.keys(subscriptionSchedules)
    .map((pid) => subscriptionSchedules[pid])
  // .sort((a, b) => Number(b.amount) - Number(a.amount));

  subscriptionSchedulesItemsAsArray.forEach((lineItem) => {
    rows.push(
      <BillingSubscriptionsItemsRow
        key={lineItem.id}
        lineItem={lineItem}
      ></BillingSubscriptionsItemsRow>
    );
  });

  if (subscriptionSchedulesItemsAsArray.length == 0 && subscriptionsItemsAsArray.length == 0) {
    rows.push(
      <Typography variant="body2" color="inherit" noWrap sx={{ flexGrow: 1 }}>
        No active subscription
      </Typography>
    )
  }

  return <div>{rows}</div>;
}

export default BillingSubscriptionsItemsTable;
