import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useParams } from "react-router";
//CSS
import "../styles/App.css";
//COMPONENTS
import Page from "./Page.js";
import Billing from "./Billing.js";

function Deal() {
  let { dealId } = useParams();
  return <Page dealId={dealId}></Page>;
}

function Bill() {
  let {customerStripeId } = useParams();
  return <Billing customerStripeId={customerStripeId}></Billing>;
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/billing/:customerStripeId" children={<Bill />} />
        <Route path="/:dealId" children={<Deal />} />
      </Switch>
    </Router>
  );
}

export default App;
