//COMPONENTS
import React from "react";
import Trans from "./Trans.js";

//Ajouter une propriété titre du devis, si elle est vide mettre : "EQUIMETRE Purchase order - nom du client"

function Topbar({ deal, company, salesOwner }) {
  var headerClassname = "quote-header quote-header-equimetre p-top-8";
  var defaultTitleKey = "";

  switch (deal.dealtype) {
    case "EQUIMETRE FLAT":
      headerClassname = "quote-header quote-header-equimetre p-top-8";
      defaultTitleKey = "quote_default_title_equimetre";
      break;
    case "EQUIMETRE TROT":
      headerClassname = "quote-header quote-header-equimetre p-top-8";
      defaultTitleKey = "quote_default_title_equimetre";
      break;
    case "EQUIMETRE VET":
      headerClassname = "quote-header quote-header-equimetre-vet p-top-8";
      defaultTitleKey = "quote_default_title_equimetre";
      break;
    case "EQUISYM":
      headerClassname = "quote-header quote-header-equisym p-top-8";
      defaultTitleKey = "quote_default_title_equisym";
      break;
    case "Equiscience":
      headerClassname = "quote-header quote-header-equiscience p-top-8";
      defaultTitleKey = "quote_default_title_equiscience";
      break;
    default:
      break;
  }

  return (
    <div className={headerClassname}>
      <div className="fixed-width text-center">
        <h1 className="quote-title p-top-4 m-bottom-8">
          {deal.titre_du_devis === null ? (
            <Trans text={defaultTitleKey}></Trans>
          ) : (
            deal.titre_du_devis
          )}
        </h1>
        <div className="p-bottom-2">
          <p className="display-inline m-x-auto is--heading-7">
            <Trans text="reference"></Trans>: {deal.hs_object_id}
          </p>
        </div>
        <div className="p-bottom-8">
          <div>
            <p className="display-inline is--heading-7">
              <Trans text="for"></Trans> {company.prenom__facturation_}{" "}
              {company.nom__facturation_} {company.name}
            </p>
          </div>
        </div>
        <div className="quote-comments-container">
          <div className="quote-comments rounded-border small-opacity">
            <div>
              <h5 className="m-bottom-4">
                <Trans text="comments"></Trans>
              </h5>
              <div className="quote-rich-text-body m-bottom-0 text-left is--heading-7 link-olaf-container">
                <pre className="m-top-2">{deal.commentaire_du_devis}</pre>
              </div>
            </div>
          </div>
          <div className="UIFlex__StyledFlex-gi5hfq-0 feaJwX private-flex comments-from-contact">
            <p className="m-bottom-0 m-right-2 is--heading-7">
              {salesOwner.firstname} {salesOwner.lastname} -{" "}
              {salesOwner.description}
            </p>
            <div className="AvatarWrapper-sc-1wyposc-0 AvatarWrapperOverlay-rroys9-0 hDrSQl contact-image">
              <div className="AvatarContentHolder__ContentHolder-jxrbz1-0 fXhlDZ">
                <img
                  className="SVGWrapper-sc-8z3nmv-0 dQeLxm"
                  alt="logo"
                  src={salesOwner.avatar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
