import i18n from "../locale/i18n";
//CSS
import "../styles/App.css";
//COMPONENTS
import Trans from "./Trans.js";
import Topbar from "./Topbar.js";
import Quote from "./Quote.js";
import Footer from "./Footer.js";

function BankTransfert({ deal, company, salesOwner, lineItems }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  var iban = <Trans text="iban_fr"></Trans>;
  var bic = <Trans text="bic_fr"></Trans>;

  if (deal) {
    console.log(deal);
    if (deal.dealtype == "Equiscience") {
      iban = "FR76 3000 4024 9700 0109 3392 577"
      bic = "BNPAFRPPCTO"
    }
    else if (deal.deal_currency_code === "USD") {
      iban = <Trans text="iban_us"></Trans>;
      bic = <Trans text="bic_us"></Trans>;
    } else if (deal.deal_currency_code === "GBP") {
      iban = <Trans text="iban_uk"></Trans>;
      bic = <Trans text="bic_uk"></Trans>;
    }
  }

  //Else -> Forms
  return (
    <div className="page">
      <div className="quote-page-container valid">
        <div className="original-quote-template">
          <div className="brofile brofile-sticky">
            <div
              wrap="wrap"
              direction="row"
              className="UIFlex__StyledFlex-gi5hfq-0 jAMQbh private-flex Brofile__ResponsiveFlex-sc-1vi4x4x-0 fFinRi brofile-content fixed-width"
            >
              <img
                alt=""
                className="private-image company-logo m-y-auto img-responsive private-image--responsive"
                src="https://cdn2.hubspot.net/hubfs/4217512/Noir-fd%20transparent-signature.png"
              ></img>
              <div className="text-center m-y-auto">
                <ul className="uiList private-list--inline quote-actions private-list--unstyled">
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/great-britain-circular.png"
                        alt="GB"
                        width="48"
                        height="48"
                        onClick={() => changeLanguage("en")}
                      />
                    </button>
                  </li>
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/france-circular.png"
                        width="48"
                        height="48"
                        alt="FR"
                        onClick={() => changeLanguage("fr")}
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Topbar
            deal={deal}
            company={company}
            salesOwner={salesOwner}
          ></Topbar>
          <p> </p>
          <div className="quote-terms-and-signature-container">
            <div
              className="quote-terms-container fixed-width"
              data-selenium-test="quotes-terms-container"
            >
              <div className="rounded-border quote-terms">
                <h4 className="m-bottom-4 text-center">
                  <Trans text="bank_transfert_title"></Trans>
                </h4>
                <div className="m-bottom-0 is--heading-7 quote-rich-text-body link-obsidian-container">
                  <p>
                    <Trans text="bank_transfert_thanks"></Trans>
                  </p>
                  <p></p>
                  <p>
                    <Trans text="bank_transfert_order"></Trans> :{" "}
                    {deal.stripe_invoice_number
                      ? deal.stripe_invoice_number
                      : deal.hs_object_id}
                  </p>
                  <p></p>
                  <p>
                    <strong>
                      <Trans text="bank_transfert_bank_details"></Trans>
                    </strong>{" "}
                    :
                  </p>
                  <p></p>
                  {deal.dealtype != "Equiscience" && (
                    <p>
                      <Trans text="bank_transfert_account_name"></Trans> : LIM
                      France
                    </p>
                  )}
                  {deal.dealtype == "Equiscience" && (
                    <p>
                      <Trans text="bank_transfert_account_name"></Trans> : LIM
                      INSTITUTE
                    </p>
                  )}
                  <p>IBAN : {iban}</p>
                  <p>BIC : {bic}</p>
                  <p></p>
                  <p>
                    <Trans text="bank_transfert_bank_address"></Trans> :
                  </p>
                  <p>BNP PARIBAS</p>
                  <p>SUD ATLANTIQUE ENTREPRISES</p>
                  <p>WOODEN PARK</p>
                  <p>14, Chemin de Magret Bat C</p>
                  <p>33700 MERIGNAC</p>
                  <p>FRANCE</p>
                  <p></p>
                  <p></p>
                  <i>
                    <Trans text="bank_transfert_wrong_method"></Trans>
                  </i>
                </div>
              </div>
            </div>
          </div>
          <Quote
            deal={deal}
            lineItems={lineItems}
            company={company}
            expirationDate={deal.date_d_expiration_du_devis}
          ></Quote>
          <Footer
            deal={deal}
            salesOwner={salesOwner}>
          </Footer>
        </div>
      </div>
    </div>
  );
}

export default BankTransfert;
