import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

//COMPONENTS
import BillingSubscriptionsItemsTable from "./BillingSubscriptionsItemsTable.js";
import Trans from "./Trans.js";

function BillingSubscriptions({ subscriptions, subscriptionSchedules }) {

    return (
        <div>
            {subscriptions != null && (
                <div className="fixed-width">
                    <Stack spacing={2}>
                        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_subscriptions" />
                        </Typography>
                        <BillingSubscriptionsItemsTable subscriptions={subscriptions} subscriptionSchedules={subscriptionSchedules} ></BillingSubscriptionsItemsTable>
                    </Stack>
                </div>
            )
            }
        </div>
    );
}

export default BillingSubscriptions;
