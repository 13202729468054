import Stack from '@mui/material/Stack';

//COMPONENTS
import BillingPaymentMethodsItemsRow from "./BillingPaymentMethodsItemsRow.js";

function BillingPaymentMethodsItemsTable({ customer, paymentMethods, onPaymentMethodsChange, loadingPaymentMethods }) {


    let paymentMethodsItemsAsArray = Object.keys(paymentMethods)
        .map((pid) => paymentMethods[pid])
    let rows = [];

    paymentMethodsItemsAsArray.forEach((paymentMethod) => {
        rows.push(
            <BillingPaymentMethodsItemsRow
                key={paymentMethod.id}
                customer={customer}
                paymentMethod={paymentMethod}
                onPaymentMethodsChange={onPaymentMethodsChange}
                loadingPaymentMethods={loadingPaymentMethods}
            ></BillingPaymentMethodsItemsRow>
        );
    });

    return (<Stack spacing={1}>
        {rows}
    </Stack>);
}

export default BillingPaymentMethodsItemsTable;
