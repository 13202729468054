import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import i18n from "../locale/i18n.js";
import { useState, useEffect } from "react";

//COMPONENTS
import Trans from "./Trans.js";
import BillingSubscriptions from "./BillingSubscriptions.js";
import BillingInvoices from "./BillingInvoices.js"
import BillingMessage from "./BillingMessage.js"
import BillingInformations from "./BillingInformations.js"
import BillingPaymentMethods from "./BillingPaymentMethods.js"
import BillingQuotes from "./BillingQuotes.js"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.arioneo.com/">
        Arioneo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

function Billing({ customerStripeId }) {
  const [state, setState] = useState({
    company: null,
    subscriptions: null,
    subscriptionSchedules: null,
    invoices: null,
    creditNotes: null,
    cegidDocs: null,
    customer: null,
    deals: null
  });

  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false)

  async function callAPI() {
    const responseCompany = await fetch("/getCompanyFromCustomerId?customerId=" + customerStripeId);
    const jsonCompany = await responseCompany.json();

    const responseDeals = await fetch("/getDealsForCompanyId?companyId=" + jsonCompany.company.id);
    const jsonDeals = await responseDeals.json();

    const responseSubscriptions = await fetch("/getSubscriptionsFromCustomerId?customerId=" + customerStripeId);
    const jsonSubscriptions = await responseSubscriptions.json();

    const responseInvoices = await fetch("/getInvoicesFromCustomerId?customerId=" + customerStripeId);
    const jsonInvoices = await responseInvoices.json();

    const responseCreditNotes = await fetch("/getCreditNotesFromCustomerId?customerId=" + customerStripeId);
    const jsonCreditNotes = await responseCreditNotes.json();

    const responseCustomer = await fetch("/getCustomerFromCustomerId?customerId=" + customerStripeId);
    const jsonCustomer = await responseCustomer.json();

    const responsePaymentMethods = await fetch("/getPaymentMethodsFromCustomerId?customerId=" + customerStripeId);
    const jsonPaymentMethods = await responsePaymentMethods.json();

    setState({
      company: jsonCompany.company,
      subscriptions: jsonSubscriptions.subscriptions,
      subscriptionSchedules: jsonSubscriptions.subscriptionSchedules,
      invoices: jsonInvoices.invoices,
      creditNotes: jsonCreditNotes.creditNotes,
      cegidDocs: jsonInvoices.pieces,
      customer: jsonCustomer.customer,
      paymentMethods: jsonPaymentMethods.paymentMethods.data,
      deals: jsonDeals
    });

    setLoadingPaymentMethods(false);
  }

  useEffect(() => {
    callAPI();
  }, [customerStripeId]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const onPaymentMethodsChange = () => {
    setLoadingPaymentMethods(true)
    callAPI();
  }

  //Else -> Forms
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Trans text="billing_title" />
          </Typography>
          <nav>
            <button className="flag-button">
              <img
                src="https://img.icons8.com/color/96/000000/great-britain-circular.png"
                alt="GB"
                width="48"
                height="48"
                onClick={() => changeLanguage("en")}
              />
            </button>
            <button className="flag-button">
              <img
                src="https://img.icons8.com/color/96/000000/france-circular.png"
                width="48"
                height="48"
                alt="FR"
                onClick={() => changeLanguage("fr")}
              />
            </button>
          </nav>
        </Toolbar>
      </AppBar>
      {/* End hero unit */}

      <Container maxWidth="xd" sx={{ mt: 3 }} >

        {state.invoices == null && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="error" />
          </Box>
        ) || (
            <Grid container spacing={3}>

              <Grid item xs={3}>

                <Stack spacing={4} sx={{ mr: 2 }}>
                  <BillingMessage
                    invoices={state.invoices}>
                  </BillingMessage>
                  <BillingInformations
                    invoices={state.invoices}
                    customer={state.customer}>
                  </BillingInformations>
                  <BillingPaymentMethods
                    customer={state.customer}
                    paymentMethods={state.paymentMethods}
                    onPaymentMethodsChange={onPaymentMethodsChange}
                    loadingPaymentMethods={loadingPaymentMethods}>
                  </BillingPaymentMethods>
                </Stack>

              </Grid>

              <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

              <Grid item xs={9}>

                <Stack spacing={4}>

                  <BillingSubscriptions
                    subscriptions={state.subscriptions}
                    subscriptionSchedules={state.subscriptionSchedules}>
                  </BillingSubscriptions>
                  <BillingInvoices
                    invoices={state.invoices}
                    creditNotes={state.creditNotes}
                    cegidDocs={state.cegidDocs}
                    paymentMethods={state.paymentMethods}
                    customer={state.customer}
                  >
                  </BillingInvoices>
                  <BillingQuotes
                    deals={state.deals}>
                  </BillingQuotes>
                </Stack>

              </Grid>
            </Grid>
          )}
      </Container>

      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </ThemeProvider >
  );
}

export default Billing;
