//COMPONENTS
import Trans from "./Trans.js";

function Footer({ deal, salesOwner }) {
  const divStyle = {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(144, 16, 16)",
  };

  switch (deal.dealtype) {
    case "EQUIMETRE FLAT":
      divStyle.backgroundColor = "rgb(144, 16, 16)";
      break;
    case "EQUIMETRE TROT":
      divStyle.backgroundColor = "rgb(144, 16, 16)";
      break;
    case "EQUIMETRE VET":
      divStyle.backgroundColor = "rgb(0, 130, 176)";
      break;
    case "EQUISYM":
      divStyle.backgroundColor = "rgb(81, 48, 105)";
      break;
    case "Equiscience":
      divStyle.backgroundColor = "rgb(36, 68, 100)"
      break;
    default:
      break;
  }

  const divStyle2 = {
    width: "48px",
  };

  return (
    <div className="quote-contact-me-section m-top-3 p-all-8" style={divStyle}>
      <div
        className="fixed-width"
        data-selenium-test="quotes-sender-information-container"
      >
        <div direction="row" wrap="nowrap" className="bgSfLk private-flex">
          <div className="eUWUxT text-center">
            <h5>
              <Trans text="footer_title"></Trans>
            </h5>
            <div>
              <div
                className="khRgtN contact-image m-bottom-1"
                style={divStyle2}
              >
                <div className="fTrqyh">
                  <img
                    alt=""
                    className="private-image img-circle private-image--circle company-image m-bottom-1 company-logo-square display-inline text-center img-responsive private-image--responsive"
                    src={salesOwner.avatar}
                  ></img>
                </div>
              </div>
              <div className="quote-contact-information undefined">
                <span className="m-bottom-1 is--text--bold">
                  <span
                    data-selenium-test="quotes-contact-name"
                    className="private-break-string private-break-string--hyphenate"
                  >
                    {salesOwner.firstname} {salesOwner.lastname}
                  </span>
                </span>
                <span className="private-break-string private-break-string--hyphenate">
                  {salesOwner.description}
                </span>
                <span className="private-break-string private-break-string--no-hyphenate">
                  {salesOwner.email}
                </span>
                <span className="private-break-string private-break-string--no-hyphenate">
                  <span>{salesOwner.tel}</span>
                </span>
              </div>
            </div>
          </div>
          {/* <div className="eUWUxT m-y-auto text-center">
              <ul className="uiList private-list--unstyled">
                <li className="uiListItem private-list__item m-bottom-4">
                  <button data-selenium-test="quotes-download-btn" data-loading="false" data-loading-state="idle" aria-disabled="false" className="uiButton private-button private-button--secondary--ghost private-button--default private-loading-button custom-color-button undefined private-button--secondary--ghost private-button--non-link" data-button-use="secondary-ghost" tabindex="0" type="button" style={{minWidth: '250px', minHeight: '50px'}}>
                    <span className="private-loading-button__content private-button--internal-spacing">
                      <Trans text='footer_download_quote'></Trans>
                    </span>
                  </button>
                </li>
                <li className="uiListItem private-list__item m-bottom-4">
                  <button data-selenium-test="quotes-print-btn" aria-disabled="false" className="uiButton private-button private-button--secondary--ghost private-button--default custom-color-button undefined private-button--non-link" data-button-use="secondary-ghost" tabindex="0" type="button" >
                    <Trans text='footer_print_quote'></Trans>
                  </button>
                </li>
              </ul>
            </div> */}
          <div className="eUWUxT text-center">
            <img
              alt=""
              className="private-image img-circle private-image--circle company-image m-bottom-1 company-logo-square display-inline text-center img-responsive private-image--responsive"
              src="https://api.hubapi.com/avatars/v1/signed-uris/1CkUKDwgEEgthcmlvbmVvLmNvbRjukdnlBSoeYnJhbmRpbmc6am9iczpvbmVvZmY6dXMtZWFzdC0xMgwxNzIuMTYuMTYuNzISGQB7DcdkRpxSwS-F1Ty3POxoiIj5TxQhBxM"
            ></img>
            <div className="quote-company-information undefined">
              <span className="private-break-string private-break-string--hyphenate">
                <span className="m-bottom-1 is--text--bold">ARIONEO</span>
              </span>
              <div>94 boulevard Auguste Blanqui</div>
              <div>Paris, 75013</div>
              <div>FR</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
